import React from "react"
import Layout from "../components/LayoutComponent"
import Seo from "../components/SeoComponent"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import "../assets/translator.css"

const Translator = () => {
  const { backgroundImage } = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "LANDSCAPE_TRANSLATOR.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `)
  const pluginImage = getImage(backgroundImage)
  const intl = useIntl()
  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: "prijevod" })}
        canonicalUrl={"translator"}
      />
      <BgImage image={pluginImage} className="bg-trans"></BgImage>
      <article className="text-trans">
        <h1>
          <FormattedMessage id="trans-article.words" />
        </h1>
        <h2>
          <FormattedMessage id="trans-article.care" />
        </h2>
        <p>
          <FormattedMessage id="trans-article.trans-one" />
        </p>
        <p>
          <FormattedMessage id="trans-article.trans-two" />
        </p>
        <p>
          <FormattedMessage id="trans-article.trans-three" />
        </p>
      </article>
    </Layout>
  )
}

export default Translator
